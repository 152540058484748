<template>
  <div class="page-go" @mouseup="selectStar">
    <n-loader :loading="$var('load')" />
    <div class="quiz">
      <div v-if="quiz.bg" class="bg">
        <img :src="quiz.bg" alt="bg" />
      </div>
      <div class="tools">
        <div v-if="active" class="prev" @click="prev">
          <n-icon icon="angle-left"/>
        </div>
        <div class="progress-bar">
          <div v-for="i in max" :key="i" :class="[ {active: i <= active+1}, ]"></div>
        </div>
      </div>

      <transition :name="'slide-'+type">
        <template v-for="(q, index) in quiz.questions">
          <div class="question" v-if="index === active" :key="q.id">
            <!--            <div v-if="quiz.logo" class="logo">-->
            <!--              <img :src="quiz.logo" alt="logo" />-->
            <!--            </div>-->
            <h1>
              <span>{{ q.title }}</span>
              <sub v-if="q.content" class="title" v-html="xss(q.content)"></sub>
            </h1>

            <div class="answers">
              <div v-if="q.type === 'simple'" class="variants">
                <div v-for="v in q.variants" :key="v.id" :class="['variant', {selected: answers[q.id] === v.id}]" @click="next(q.id, v.id)">
                  {{ v.title }}
                </div>
              </div>
              <div v-else-if="q.type === 'multi'" class="variants">
                <div class="info">Выберите один или несколько вариантов</div>
                <div v-for="v in q.variants" :key="v.id" :class="['variant', {selected: (answers[q.id] || []).includes(v.id)}]" @click="selectMulti(q.id, v.id)">
                  {{ v.title }}
                </div>
              </div>
              <div v-else-if="q.type === 'stars'" class="stars" @mousedown="down = true" @click="selectStar">
                <i v-for="i in 5" :key="i" :class="[ (i>(answers[q.id]||0))?'far':'fas', 'fa-star', ]" @mousedown="() => { down = true; hoverStar(q.id, i) }" @mouseenter="hoverStar(q.id, i)"></i>
              </div>
              <div v-else-if="q.type === 'text'" class="text">
                <textarea :value="answers[q.id]" @input="(e) => changeTextarea(q.id, e.target.value)"></textarea>
              </div>
            </div>

            <div class="buttons">
              <template v-if="[ 'multi', 'text', ].includes(q.type)">
                <n-button icon-right="angle-right" @click="next(q.id)">Далее</n-button>
              </template>
            </div>
          </div>
        </template>
      </transition>

    </div>
  </div>
</template>

<script>
import xss from 'xss'

export default {
  name: 'PageGo',
  html() {
    return {
      title: this.quiz.title,
      meta: [
        { mid: 'og:title', property: 'og:title', content: this.quiz.title, },
        { mid: 'og:description', property: 'og:description', content: this.quiz.content, },
        { mid: 'og:image', property: 'og:image', content: this.quiz.logo, },
        // { mid: 'theme-color', name: 'theme-color', content: this.quiz.color, },
      ],
    }
  },
  data() {
    return {
      quiz: {},
      active: 0,
      max: 0,
      type: 'right',
      answers: {},
      down: false,
      star: 0,
      starQuizId: 0,
    }
  },
  computed: {
    code() {
      return this.$route.params.code
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    xss,
    load() {
      this.$var('load', true)
      $api.quiz.get(this.code).then((response) => {
        this.quiz = response.data.data
        this.max = this.quiz.questions.length
        document.querySelector('.layout-quiz').style.setProperty('--primary', this.quiz.color || '#00a600');
        setTimeout(() => {
          this.$var('load', false)
        }, 500)
      })
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    selectMulti(key, v) {
      if ((this.answers[key] || []).includes(v)) {
        this.$set(this.answers, key, $n.filter(this.answers[key], (i) => i !== v))
      } else {
        const answers = $n.clone(this.answers[key]) || []
        answers.push(v)
        this.$set(this.answers, key, answers)
      }
    },
    hoverStar(key, v) {
      if (this.down) {
        this.starQuizId = key
        this.star = v
        this.$set(this.answers, key, v)
      }
    },
    selectStar() {
      if (this.down) {
        this.$set(this.answers, this.starQuizId, this.star)
        this.down = false
        this.next(this.starQuizId, this.star)
        this.starQuizId = 0;
        this.star = 0;
      }
    },
    next(key, v = null) {
      if (v) {
        this.$set(this.answers, key, v)
      }
      this.scrollToTop()
      this.type = 'right'
      if (this.active === this.max - 1) {
        this.$var('load', true)
        $api.quiz.end(this.quiz.id, this.answers).then((response) => {
          this.$var('load', false)
          this.$router.push({ name: 'app.end', params: { code: this.code, }})
          this.scrollToTop()
        })
        return
      }
      setTimeout(() => {
        this.active++
      }, 200)
    },
    prev() {
      this.type = 'left'
      this.scrollToTop()
      if (this.active > 0) {
        this.active--
        this.scrollToTop()
      }
    },
    changeTextarea(key, v) {
      this.$set(this.answers, key, v)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-go {
  /*--primary: #00a600;*/
  /*--primary: #841a7b;*/
  //min-height: 100vh;

  .quiz {
    position: relative;
    overflow-x: hidden;

    .bg {
      opacity: .1;
      position: fixed;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .question {
    transition: all .5s;
    width: 100%;
    text-align: center;
    padding: 40px;
    //height: calc(100vh - 110px);
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    &>* {
      flex-basis: 0;
    }

    .logo {
      width: 100%;
      padding: 30px 0;
      height: 200px;
      text-align: center;
      img {
        max-width: 200px;
        max-height: 100%;
      }
    }
    .logo+h1 {
      margin-top: 20px;
    }
    h1 {
      font-size: 1.6em;
      margin: 70px 0 20px;
      font-weight: bold;

      sub {
        display: inline-block;
        width: 100%;
        margin-top: 20px;
        font-weight: lighter;
        font-size: 0.7em;
        line-height: 1.2;
      }
    }

    .buttons {
      text-align: right;
      flex-basis: content;
      .n-button {
        padding: 15px 30px;
        //margin-top: 30px;
        background: var(--primary);
        color: #fff;
      }
    }
  }

  .answers {
    margin-top: 40px;
    flex-grow: 1;

    .info {
      font-size: .9em;
      opacity: .5;
      font-style: italic;
    }
  }

  .variant {
    transition: all .3s;
    margin: 15px auto;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    border: 1px solid #e6e6e6;

    &.selected, &::selection {
      background: var(--primary);
      color: #fff;
    }
  }

  .stars {
    white-space: nowrap;
    i {
      font-size: 2.4em;
      padding: 10px 7px;
      cursor: pointer;
      color: var(--primary);
    }
  }

  .text {
    textarea {
      padding: 15px;
      max-width: 100%;
      min-width: 100%;
      min-height: 150px;
      max-height: 500px;
      border: 1px solid #ddd;
      outline: none;
    }
  }


  .tools {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: #fff;

    .prev, .info {
      position: absolute;
      top: 20px;
      padding: 20px;
    }

    .prev {
      left: 0;
      cursor: pointer;
      margin-left: 20px;
      font-size: 1.5em;
      opacity: .3;
    }

    .progress-bar {
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: stretch;
      div {
        height: 8px;
        width: 100%;
        margin: 0 2px;
        background: #ccc;
        border-radius: 3px;
        &.active {
          background: var(--primary);
        }
      }
    }

    .info {
      right: 0;
      margin-right: 20px;
    }
  }


  .slide-right-enter, .slide-left-enter {
    transform: translateX(100%);
    opacity: 0;
    position: absolute;
    top: 0;
  }

  .slide-right-enter-to, .slide-left-enter-to {
    transform: translateX(0);
    position: absolute;
    top: 0;
  }

  .slide-right-leave, .slide-left-leave {
    transform: translateX(0);
  }

  .slide-right-leave-to, .slide-left-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }

  .slide-left-enter {
    transform: translateX(-100%);
  }

  .slide-left-leave-to {
    transform: translateX(100%);
  }

}
</style>
